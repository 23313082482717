import React from "react";
import PropTypes from "prop-types";
import BasicPageLayout from "~/components/BasicPageLayout";
import {getMatchDetailsTitleSubject} from "~/util/match";
import {getCandidateMatchDisplayValue} from "~/util/match-display-values";
import config from "~/config";
import {INDEX_DEFAULTS} from "~/config/base-config";
import {FormattedMessage} from "react-intl";
import styles from "./styles.module.scss";
import MatchDetailsOverview from "~/components/MatchDetailsOverview";

export default class StandaloneMatchDetails extends React.PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired, // react-router
        sourceIndex: PropTypes.string,
        sourceId: PropTypes.string,
        targetIndex: PropTypes.string,
        targetId: PropTypes.string,
        result: PropTypes.any,
        error: PropTypes.any,
        setSourceAndTarget: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const {match, setSourceAndTarget} = this.props;

        setSourceAndTarget(
            match.params.sourceIndex,
            match.params.sourceId,
            match.params.targetIndex,
            match.params.targetId
        );
    }

    render() {
        const {result, error} = this.props;
        return (
            <BasicPageLayout isLoading={!result && !error}>{this.renderContent()}</BasicPageLayout>
        );
    }

    renderContent() {
        const {result} = this.props;

        if (!result) {
            return null;
        }

        const config = this.getTargetIndexConfig();

        const labels = {
            forwardLabel: <FormattedMessage id="matchQuality.searchProfile" />,
            forwardSubLabel: undefined,
            reverseLabel: getMatchDetailsTitleSubject(result),
        };

        return (
            <div className={styles.content}>
                <MatchDetailsOverview
                    match={result}
                    extraPropertiesOrganization={config.extraProperties}
                    extraPropertyPhoneNumbers={config.extraPropertyPhoneNumbers}
                    forwardLabel={labels.forwardLabel}
                    forwardSubLabel={labels.forwardSubLabel}
                    reverseLabel={labels.reverseLabel}
                    getMatchDisplayValue={this.getMatchDisplayValue}
                />
            </div>
        );
    }

    getMatchDisplayValue = (match, section) => {
        if (section === "header") {
            return getMatchDetailsTitleSubject(match);
        } else {
            return getCandidateMatchDisplayValue(match, section);
        }
    };

    getTargetIndexConfig() {
        // TODO: This probably needs its own configuration...

        const {targetIndex} = this.props;

        if (!targetIndex) {
            return INDEX_DEFAULTS;
        }

        const indexConfigs = config("features.profileToCandidates.indices", []);

        for (const indexConfig of indexConfigs) {
            if (indexConfig.name === targetIndex) {
                return indexConfig;
            }
        }

        return INDEX_DEFAULTS;
    }
}
