import React from "react";
import PropTypes from "prop-types";
import * as immutable from "object-path-immutable";
import Layout from "./Layout";
import ProfileType from "~/enums/ProfileType";
import ScoreType from "~/enums/ScoreType";
import ExpansionType from "~/enums/ExpansionType";
import PredicateEditorPropTypes from "~/components/PredicateEditor/prop-types";
import {isFunction} from "~/util/misc";
import {createSelectors} from "~/components/MatchingPage/selectors";
import {registerTmbDebugUtil} from "~/util/tmb-debug-util";
import {removeKeysFromPredicate} from "~/components/PredicateEditor/util";
import MatchSourceEntity from "~/entities/MatchSourceEntity";
import Api from "~/api";
import {mergeAspectValues} from "~/util/match-profile";
import FetchState from "~/enums/FetchState";
import TranslatableLabelPropType from "~/prop-types/translatable-label";
import SelectAllState from "~/enums/SelectAllState";

const GlobalRequestPropType = PropTypes.shape({
    matchProfile: PropTypes.object.isRequired,
});

const IndexRequestPropType = PropTypes.shape({
    filters: PropTypes.object.isRequired,
    customFiltersPredicate: PredicateEditorPropTypes.group.isRequired,
    sortMode: PropTypes.string,
});

export default class MatchingPage extends React.PureComponent {
    static propTypes = {
        additionalMenuContent: PropTypes.node, // TODO: Find another solution
        isLoading: PropTypes.bool,
        configuration: PropTypes.shape({
            enabled: PropTypes.bool.isRequired,
            indices: PropTypes.arrayOf(
                PropTypes.shape({
                    pageSize: PropTypes.number.isRequired,
                    resultType: ProfileType.propType.isRequired,
                    label: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                    selectionEndpoints: PropTypes.shape({
                        get: PropTypes.string.isRequired,
                        set: PropTypes.string.isRequired,
                    }),
                    scoreType: ScoreType.propType,
                    expansionType: ExpansionType.propType,
                    filters: PropTypes.arrayOf(PropTypes.string).isRequired,
                    sortModeGroup: PropTypes.string,
                    allowSelection: PropTypes.bool.isRequired,
                    allowComparison: PropTypes.bool.isRequired,
                    allowDownload: PropTypes.bool.isRequired,
                    exportType: PropTypes.string.isRequired,
                    showMatchButton: PropTypes.bool.isRequired,
                    extraColumns: PropTypes.arrayOf(
                        PropTypes.shape({
                            label: TranslatableLabelPropType.isRequired,
                            property: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
                                .isRequired,
                        })
                    ).isRequired,
                    detailsTitleProperty: PropTypes.string,
                    extraProperties: PropTypes.object, // TODO: Proper prop-typing
                    handleVisitExternal: PropTypes.func,
                    customFiltersEnabled: PropTypes.bool.isRequired,
                    createNoteCallback: PropTypes.func,
                    noteTypes: PropTypes.arrayOf(
                        PropTypes.shape({
                            value: PropTypes.any.isRequired,
                            label: PropTypes.string.isRequired,
                        })
                    ),
                    defaultNoteType: PropTypes.any,
                })
            ).isRequired,
            matchSourceEntity: PropTypes.shape({
                candidateIndex: PropTypes.string.isRequired,
                candidateMatchingStrategy: PropTypes.string,
                jobIndex: PropTypes.string.isRequired,
                jobMatchingStrategy: PropTypes.string,
            }),
            keywordSearch: PropTypes.bool.isRequired,
            keywordSuggestions: PropTypes.bool.isRequired,
        }).isRequired,
        matchSourceEntityQueryParameters: PropTypes.shape({
            type: PropTypes.oneOf(["job", "candidate"]).isRequired,
            id: PropTypes.string.isRequired,
        }),
        matchSourceEntityDefaultType: ProfileType.propType,
        state: PropTypes.shape({
            query: PropTypes.string.isRequired,
            nextRequest: GlobalRequestPropType.isRequired,
            lastRequest: GlobalRequestPropType.isRequired,
            matchingStrategy: PropTypes.string,
            matchSourceEntity: PropTypes.instanceOf(MatchSourceEntity),
            results: PropTypes.objectOf(
                PropTypes.shape({
                    nextRequest: IndexRequestPropType.isRequired,
                    lastRequest: IndexRequestPropType.isRequired,
                    matches: PropTypes.object.isRequired, // TODO: Proper prop-typing
                    pages: PropTypes.objectOf(
                        PropTypes.shape({
                            ids: PropTypes.arrayOf(PropTypes.string).isRequired,
                            promise: PropTypes.instanceOf(Promise),
                            state: FetchState.propType.isRequired,
                        })
                    ),
                    count: PropTypes.number,
                    selection: PropTypes.arrayOf(PropTypes.string).isRequired,
                    backendSelection: PropTypes.shape({
                        data: PropTypes.object.isRequired,
                    }),
                })
            ).isRequired,
        }).isRequired,
        language: PropTypes.string.isRequired,
        newMatch: PropTypes.func.isRequired,
        updateMatchForIndex: PropTypes.func.isRequired,
        requestPage: PropTypes.func.isRequired,
        makeSelection: PropTypes.func.isRequired,
        selectAllFromApi: PropTypes.func.isRequired,
        onMatchSourceEntityChange: PropTypes.func.isRequired,
        onLanguageChange: PropTypes.func.isRequired,
        onQueryChange: PropTypes.func.isRequired,
        onClearNextRequest: PropTypes.func.isRequired,
        onResetNextRequest: PropTypes.func.isRequired,
        onMatchingStrategyChange: PropTypes.func.isRequired,
        onNextRequestChange: PropTypes.func.isRequired,
        onIndexNextRequestChange: PropTypes.func.isRequired,
        onSelectionChange: PropTypes.func.isRequired,
        onDownload: PropTypes.func.isRequired,
        onSortModeChange: PropTypes.func.isRequired,
        onSelectionStatusChange: PropTypes.func.isRequired,
        onUiStateUpdate: PropTypes.func.isRequired,
        onMatchToOthers: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            processingSelection: false,
            displayedMatches: [],
        };

        this.selectors = createSelectors();
        this.matchPageRef = React.createRef();
        this.matchListRef = React.createRef();
    }

    componentDidMount() {
        this.useMatchingEntity(this.createMatchingEntity());

        registerTmbDebugUtil("customFilters", () => {
            const allCustomFilters = [this.props.state.nextRequest.customFiltersPredicate];

            for (const indexName in this.props.state.results) {
                allCustomFilters.push(this.props.state.results[indexName].nextRequest.customFiltersPredicate);
            }

            return allCustomFilters.filter(x => x !== undefined, removeKeysFromPredicate);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateMatchingEntity(prevProps);
    }

    createMatchingEntity() {
        const {
            matchSourceEntityQueryParameters,
            matchSourceEntityDefaultType,
            configuration,
            language,
        } = this.props;

        if (!configuration.matchSourceEntity) {
            return undefined;
        }

        return MatchSourceEntity.fromMatchParams(
            {
                [ProfileType.CANDIDATE]: configuration.matchSourceEntity.candidateIndex,
                [ProfileType.JOB]: configuration.matchSourceEntity.jobIndex,
            },
            matchSourceEntityQueryParameters,
            matchSourceEntityDefaultType,
            {
                jobMatchingStrategy: configuration.matchSourceEntity.jobMatchingStrategy,
                candidateMatchingStrategy:
                    configuration.matchSourceEntity.candidateMatchingStrategy,
            },
            language
        );
    }

    useMatchingEntity(matchingEntity) {
        if (matchingEntity !== undefined) {
            this.matchPageRef.current.toggleMatchProfileTab(false);
            this.matchPageRef.current.toggleResultsTab(true);
        }

        this.props.onMatchSourceEntityChange(matchingEntity);
    }

    updateMatchingEntity(prevProps) {
        const {matchSourceEntityQueryParameters} = this.props;

        if (
            matchSourceEntityQueryParameters !== undefined &&
            prevProps.matchSourceEntityQueryParameters !== undefined &&
            matchSourceEntityQueryParameters.type ===
                prevProps.matchSourceEntityQueryParameters.type &&
            matchSourceEntityQueryParameters.index ===
                prevProps.matchSourceEntityQueryParameters.index &&
            matchSourceEntityQueryParameters.id === prevProps.matchSourceEntityQueryParameters.id
        ) {
            return;
        } else if (
            matchSourceEntityQueryParameters === undefined &&
            prevProps.matchSourceEntityQueryParameters === undefined
        ) {
            return;
        }

        const matchingEntity = this.props.state.matchSourceEntity;
        const nextMatchingEntity = this.createMatchingEntity();

        if (!MatchSourceEntity.areSame(matchingEntity, nextMatchingEntity)) {
            this.useMatchingEntity(nextMatchingEntity);
        }
    }

    render() {
        const {
            additionalMenuContent,
            isLoading,
            configuration,
            state,
            language,
            updateMatchForIndex,
            requestPage,
            makeSelection,
            selectAllFromApi,
            onSelectionChange,
            onLanguageChange,
            onQueryChange,
            onMatchingStrategyChange,
            onDownload,
            onSortModeChange,
            onSelectionStatusChange,
            onUiStateUpdate,
            onMatchToOthers,
        } = this.props;

        return (
            <Layout
                configuration={configuration}
                state={state}
                additionalMenuContent={additionalMenuContent}
                language={language}
                isLoading={isLoading}
                showResetButton={
                    state.matchSourceEntity !== undefined &&
                    state.matchSourceEntity.document !== undefined
                }
                updateMatchForIndex={updateMatchForIndex}
                requestPage={requestPage}
                makeSelection={makeSelection}
                selectAllFromApi={selectAllFromApi}
                onKeywordQueryChange={onQueryChange}
                onLanguageChange={onLanguageChange}
                onMatchingStrategyChange={onMatchingStrategyChange}
                onCustomFiltersPredicateChange={this.handleCustomFiltersPredicateChange}
                onMatchProfileChange={this.handleMatchProfileChange}
                onFiltersChange={this.handleFiltersChange}
                onSortModeChange={onSortModeChange}
                onSelectionChange={onSelectionChange}
                onSelectionStatusChange={onSelectionStatusChange}
                onUiStateUpdate={onUiStateUpdate}
                onDownload={onDownload}
                onMatchToOthers={onMatchToOthers}
                onMatch={this.handleMatch}
                onClear={this.handleClear}
                onReset={this.handleReset}
                ref={this.matchPageRef}
            />
        );
    }

    handleMatchProfileChange = matchProfile => {
        const nextRequest = this.props.state.nextRequest;
        this.props.onNextRequestChange({...nextRequest, matchProfile});
    };

    handleFiltersChange = filters => {
        const {onIndexNextRequestChange, configuration, state} = this.props;
        const firstIndex = configuration.indices[0];

        onIndexNextRequestChange(firstIndex, {
            ...state.results[firstIndex.name].nextRequest,
            filters,
        });
    };

    handleCustomFiltersPredicateChange = customFiltersPredicate => {
        const {onIndexNextRequestChange, configuration, state} = this.props;
        const firstIndex = configuration.indices[0];

        onIndexNextRequestChange(firstIndex, {
            ...state.results[firstIndex.name].nextRequest,
            customFiltersPredicate,
        });
    };

    handleMatch = parseAndMatch => {
        if (parseAndMatch === true) {
            this.handleParseAndMatch();
        } else {
            this.props.newMatch();
            this.matchPageRef.current.openResultsTab();
        }
    };

    handleParseAndMatch = () => {
        const {state, language, onNextRequestChange, onQueryChange} = this.props;

        onQueryChange("");

        if (state.query.trim() !== "") {
            Api.parseQuery(state.query, language).then(matchProfile => {
                onNextRequestChange({
                    ...state.nextRequest,
                    matchProfile: mergeAspectValues(state.nextRequest.matchProfile, matchProfile),
                });
                this.handleMatch();
            });
        } else {
            this.handleMatch();
        }
    };

    handleClear = () => {
        this.props.onClearNextRequest();
    };

    handleReset = () => {
        this.props.onResetNextRequest();
    };

    forResultType = values => {
        const value = values[this.props.configuration.resultType];
        return isFunction(value) ? value() : value;
    };
}
