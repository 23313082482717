import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import Spinner from "react-bootstrap/Spinner";
import FetchState from "~/enums/FetchState";
import {FormattedMessage} from "react-intl";
import styles from "./styles.module.scss";

export default class Tab extends React.PureComponent {
    static propTypes = {
        index: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        isActive: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    render() {
        const {index, isActive} = this.props;

        return (
            <div className={c(styles.tab, {isActive})} onClick={this.handleClick}>
                {index.label}
                {this.renderMatchSpinner()}
                {this.renderResultsCount()}
            </div>
        );
    }

    renderResultsCount() {
        const {state} = this.props;

        if (state.count === undefined) {
            return null;
        }

        return (
            <span className="ml-2 text-secondary">
                <FormattedMessage id="results.count" values={{count: state.count}} />
            </span>
        );
    }

    renderMatchSpinner() {
        const {state} = this.props;

        const isMatching =
            Object.values(state.pages).find(page => page.state === FetchState.FETCHING) !==
            undefined;

        if (!isMatching) {
            return null;
        }

        return (
            <span className="ml-2 text-secondary">
                <Spinner animation="grow" role="status" size="sm" />{" "}
                <FormattedMessage id="progress.matching" />
            </span>
        );
    }

    handleClick = () => {
        const {index, onClick} = this.props;
        onClick(index);
    };
}
