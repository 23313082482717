import objectPath from "object-path";
import ProfileType from "~/enums/ProfileType";
import Api from "~/api";
import uuid from "uuid/v4";
import * as immutable from "object-path-immutable";
import FetchState from "~/enums/FetchState";
import {addToExistingSelection} from "~/util/misc";
import ContentDisposition from "content-disposition";
import config from "~/config";
import MatchSourceEntity from "~/entities/MatchSourceEntity";
import context from "react-router/modules/RouterContext";

export const CREATE_CONTEXT = "matching/CREATE_CONTEXT";
export const NEW_MATCH_STARTED = "matching/NEW_MATCH_STARTED";
export const PAGE_REQUESTED = "matching/PAGE_REQUESTED";
export const PAGE_RECEIVED = "matching/PAGE_RECEIVED";
export const PAGE_REQUEST_ERROR = "matching/PAGE_REQUEST_ERROR";
export const BACKEND_SELECTION_REQUESTED = "matching/BACKEND_SELECTION_REQUESTED";
export const BACKEND_SELECTION_RECEIVED = "matching/BACKEND_SELECTION_RECEIVED";
export const BACKEND_SELECTION_REQUEST_ERROR = "matching/BACKEND_SELECTION_REQUEST_ERROR";
export const SET_QUERY = "matching/SET_QUERY";
export const SET_MATCHING_STRATEGY = "matching/SET_MATCHING_STRATEGY";
export const SET_NEXT_REQUEST = "matching/SET_NEXT_REQUEST";
export const SET_LAST_REQUEST = "matching/SET_LAST_REQUEST";
export const CLEAR_NEXT_REQUEST = "matching/CLEAR_NEXT_REQUEST";
export const RESET_NEXT_REQUEST = "matching/RESET_NEXT_REQUEST";
export const SET_SELECTION = "matching/SET_SELECTION";
export const SET_DISPLAYED_PAGE = "matching/SET_DISPLAYED_PAGE";
export const SET_MATCH_SOURCE_ENTITY = "matching/SET_MATCH_SOURCE_ENTITY";
export const MATCH_SOURCE_ENTITY_LOADED = "matching/MATCH_SOURCE_ENTITY_LOADED";
export const SET_INDEX_NEXT_REQUEST = "matching/SET_INDEX_NEXT_REQUEST";
export const SET_INDEX_LAST_REQUEST = "matching/SET_INDEX_LAST_REQUEST";
export const SET_SORT_MODE = "matching/SET_SORT_MODE";
export const SET_SELECTION_STATUS = "matching/SET_SELECTION_STATUS";
export const UPDATE_UI_STATE = "matching/UPDATE_UI_STATE";
export const REPLACE_MATCH = "matching/REPLACE_MATCH";

const BACKEND_SELECTION_SPACING_MILLISECONDS = 30 * 1000;

export function createMatchingContext(id, configurationFn) {
    return (dispatch, getState) => {
        const configuration = configurationFn(getState());

        dispatch({
            type: CREATE_CONTEXT,
            id,
            configurationFn,
            configuration,
        });
    };
}

export function actionsForMatchingContext(contextId) {
    const actions = {
        newMatch,
        updateMatchForIndex,
        requestPage,
        makeSelection,
        setMatchSourceEntity,
        setQuery,
        setMatchingStrategy,
        setNextRequest,
        setIndexNextRequest,
        clearNextRequest,
        resetNextRequest,
        setSelection,
        downloadMatchResults,
        setSortMode,
        setSelectionStatus,
        updateUiState,
        selectAllFromApi,
    };

    return Object.keys(actions).reduce((mappedActions, key) => {
        mappedActions[key] = actions[key].bind(undefined, contextId);
        return mappedActions;
    }, {});
}

export function replaceMatch(contextId, indexName, matchId, data) {
    return {
        type: REPLACE_MATCH,
        contextId,
        indexName,
        matchId,
        data,
    };
}

function newMatch(contextId) {
    return (dispatch, getState) => {
        const matchingContext = getMatchingContext(getState(), contextId);
        const configuration = matchingContext.configurationFn(getState());
        const promises = [];

        dispatch(newMatchStarted(contextId));

        for (let i = 0; i < configuration.indices.length; i++) {
            const index = configuration.indices[i];
            const indexState = matchingContext.results[index.name];

            promises.push(
                startMatchForIndex(
                    dispatch,
                    getState,
                    contextId,
                    matchingContext,
                    index,
                    indexState.nextRequest,
                    matchingContext.nextRequest
                )
            );
        }

        dispatch(setLastRequest(contextId, matchingContext.nextRequest));
        return Promise.all(promises);
    };
}

function updateMatchForIndex(contextId, index) {
    return (dispatch, getState) => {
        const matchingContext = getMatchingContext(getState(), contextId);
        const indexState = matchingContext.results[index.name];

        dispatch(newMatchStarted(contextId, index));

        return startMatchForIndex(
            dispatch,
            getState,
            contextId,
            matchingContext,
            index,
            indexState.nextRequest,
            matchingContext.lastRequest
        );
    };
}

function startMatchForIndex(
    dispatch,
    getState,
    contextId,
    matchingContext,
    index,
    indexNextRequest,
    contextNextRequest
) {
    const promiseId = uuid();
    dispatch(pageRequested(contextId, index, 0, promiseId, undefined));

    return requestBackendSelectionIfNeeded(dispatch, contextId, index, matchingContext).then(
        backendSelection => {
            const currentlyActivePromiseId = immutable.get(getState(), [
                "matching",
                "contexts",
                contextId,
                "results",
                index.name,
                "pages",
                0,
                "promiseId",
            ]);

            if (currentlyActivePromiseId !== promiseId) {
                return Promise.resolve();
            }

            const finalIndexNextRequest = {...indexNextRequest};

            if (config("matching.excludeExistingMatches")) {
                finalIndexNextRequest.excludedIds = [
                    ...finalIndexNextRequest.excludedIds,
                    ...getExcludedIds(backendSelection, index.resultType),
                ];
            }

            const matchRequest = {
                ...contextNextRequest,
                ...finalIndexNextRequest,
            };

            const promise = apiCallPromiseForIndex(getState(), contextId, index, {
                matchRequest,
                from: 0,
                size: index.pageSize,
            })
                .then(response => {
                    const {matches, ids, count} = convertMatchResponse(response);
                    dispatch(pageReceived(contextId, index, 0, promiseId, matches, ids, count));
                })
                .catch(reason => {
                    dispatch(pageRequestError(contextId, index, 0, promiseId));
                    throw reason;
                });

            dispatch(setIndexLastRequest(contextId, index, finalIndexNextRequest));
            dispatch(pageRequested(contextId, index, 0, promiseId, promise));
            return promise;
        }
    );
}

function requestPage(contextId, index, pageNumber) {
    return (dispatch, getState) => {
        const matchingContext = getMatchingContext(getState(), contextId);
        const indexResults = objectPath.get(matchingContext, ["results", index.name]);
        const page = objectPath.get(indexResults, ["pages", pageNumber], undefined);

        if (page !== undefined) {
            if (page.state === FetchState.FETCHED) {
                dispatch(setDisplayedPage(contextId, index, pageNumber));
                return page.promise;
            } else if (page.state === FetchState.FETCHING) {
                return page.promise;
            }
        }

        const promiseId = uuid();
        const promise = apiCallPromiseForIndex(getState(), contextId, index, {
            from: pageNumber * index.pageSize,
            size: index.pageSize,
        })
            .then(response => {
                const {matches, ids, count} = convertMatchResponse(response);
                dispatch(
                    pageReceived(contextId, index, pageNumber, promiseId, matches, ids, count)
                );
            })
            .catch(reason => {
                dispatch(pageRequestError(contextId, index, pageNumber, promiseId));
                throw reason;
            });

        dispatch(pageRequested(contextId, index, pageNumber, promiseId, promise));
        return promise;
    };
}

function makeSelection(contextId, index, selection) {
    return (dispatch, getState) => {
        const matchingContext = getMatchingContext(getState(), contextId);

        if (!settingSelectionPossible(index, matchingContext)) {
            return;
        }

        const indexResults = matchingContext.results[index.name];
        const backendSelection = indexResults.backendSelection.data;
        const filteredSelection = selection.filter(
            id =>
                (!backendSelection.candidatesForJob || !backendSelection.candidatesForJob[id]) &&
                (!backendSelection.jobsForCandidate || !backendSelection.jobsForCandidate[id])
        );

        if (filteredSelection.length > 0) {
            const promise = Api.makeSelection(
                index.selectionEndpoints.set,
                matchingContext.matchSourceEntity.id,
                filteredSelection,
                indexResults.selectionStatus
            )
                .then(result => {
                    if (result.hasErrors) {
                        console.error(result.message);
                        throw new Error("Problem setting selection:" + result.message);
                    }

                    return Api.fetchSelection(
                        index.selectionEndpoints.get,
                        matchingContext.matchSourceEntity.id
                    );
                })
                .then(data => {
                    data = convertOldStyleSelectionData(index.resultType, data);

                    // The Carerix API is sometimes slow to update, so we make sure we add the selection ourselves
                    addToExistingSelection(data.candidatesForJob, filteredSelection);
                    addToExistingSelection(data.jobsForCandidate, filteredSelection);

                    dispatch(backendSelectionReceived(contextId, index, data));
                    dispatch(setSelection(contextId, index, []));
                })
                .catch(error => {
                    dispatch(backendSelectionRequestError(contextId, index));
                    dispatch(setSelection(contextId, index, []));
                    throw error;
                });

            dispatch(backendSelectionRequested(contextId, index, promise, true));
        } else {
            dispatch(setSelection(contextId, index, []));
        }
    };
}

function requestBackendSelectionIfNeeded(dispatch, contextId, index, matchingContext) {
    if (!gettingSelectionPossible(index, matchingContext)) {
        return Promise.resolve({});
    }

    const backendSelection = matchingContext.results[index.name].backendSelection;
    const now = new Date();

    if (backendSelection.state === FetchState.FETCHING) {
        return backendSelection.promise;
    } else if (
        backendSelection.state === FetchState.FETCHED &&
        now - backendSelection.lastRequestAt < BACKEND_SELECTION_SPACING_MILLISECONDS
    ) {
        return backendSelection.promise;
    }

    const promise = Api.fetchSelection(
        index.selectionEndpoints.get,
        matchingContext.matchSourceEntity.id
    )
        .then(data => {
            data = convertOldStyleSelectionData(index.resultType, data);
            dispatch(backendSelectionReceived(contextId, index, data));
            return data;
        })
        .catch(e => {
            dispatch(backendSelectionRequestError(contextId, index));
            console.error("Coulnd't get existing selection");
            console.error(e);
            return {};
        });

    dispatch(backendSelectionRequested(contextId, index, promise, false));
    return promise;
}

function gettingSelectionPossible(index, matchingContext) {
    return (
        index.allowSelection &&
        index.selectionEndpoints &&
        index.selectionEndpoints.get &&
        matchingContext.matchSourceEntity
    );
}

function settingSelectionPossible(index, matchingContext) {
    return (
        index.allowSelection &&
        index.selectionEndpoints &&
        index.selectionEndpoints.set &&
        matchingContext.matchSourceEntity
    );
}

function newMatchStarted(contextId, singleIndex) {
    return {
        type: NEW_MATCH_STARTED,
        contextId,
        singleIndex,
    };
}

function setLastRequest(contextId, lastRequest) {
    return {
        type: SET_LAST_REQUEST,
        contextId,
        lastRequest,
    };
}

function setIndexLastRequest(contextId, index, lastRequest) {
    return {
        type: SET_INDEX_LAST_REQUEST,
        contextId,
        index,
        lastRequest,
    };
}

function pageRequested(contextId, index, pageNumber, promiseId, promise) {
    return {
        type: PAGE_REQUESTED,
        contextId,
        index,
        pageNumber,
        promiseId,
        promise,
    };
}

function pageReceived(contextId, index, pageNumber, promiseId, matches, ids, count) {
    return {
        type: PAGE_RECEIVED,
        contextId,
        index,
        pageNumber,
        promiseId,
        matches,
        ids,
        count,
    };
}

function pageRequestError(contextId, index, pageNumber, promiseId) {
    return {
        type: PAGE_REQUEST_ERROR,
        contextId,
        index,
        pageNumber,
        promiseId,
    };
}

function backendSelectionRequested(contextId, index, promise, isMakingSelection) {
    return {
        type: BACKEND_SELECTION_REQUESTED,
        contextId,
        index,
        promise,
        isMakingSelection,
    };
}

function backendSelectionReceived(contextId, index, data) {
    return {
        type: BACKEND_SELECTION_RECEIVED,
        contextId,
        index,
        data,
    };
}

function backendSelectionRequestError(contextId, index) {
    return {
        type: BACKEND_SELECTION_REQUEST_ERROR,
        contextId,
        index,
    };
}

function setDisplayedPage(contextId, index, pageNumber) {
    return {
        type: SET_DISPLAYED_PAGE,
        contextId,
        index,
        pageNumber,
    };
}

function setMatchSourceEntity(contextId, matchSourceEntity) {
    return (dispatch, getState) => {
        const matchingContext = getMatchingContext(getState(), contextId);
        const configuration = matchingContext.configurationFn(getState());

        if (!MatchSourceEntity.areSame(matchingContext.matchSourceEntity, matchSourceEntity)) {
            dispatch({
                type: SET_MATCH_SOURCE_ENTITY,
                contextId,
                configuration,
                matchSourceEntity,
            });

            if (matchSourceEntity !== undefined) {
                matchSourceEntity.documentPromise.then(() => {
                    dispatch({
                        type: MATCH_SOURCE_ENTITY_LOADED,
                        contextId,
                        configuration,
                        matchSourceEntity,
                    });

                    dispatch(newMatch(contextId));
                });
            } else {
                dispatch(clearNextRequest(contextId));
            }
        }
    };
}

function setQuery(contextId, query) {
    return {
        type: SET_QUERY,
        contextId,
        query,
    };
}

function setMatchingStrategy(contextId, matchingStrategy) {
    return {
        type: SET_MATCHING_STRATEGY,
        contextId,
        matchingStrategy,
    };
}

export function setNextRequest(contextId, nextRequest) {
    return {
        type: SET_NEXT_REQUEST,
        contextId,
        nextRequest,
    };
}

function clearNextRequest(contextId, overrides) {
    return (dispatch, getState) => {
        const matchingContext = getMatchingContext(getState(), contextId);
        const configuration = matchingContext.configurationFn(getState());

        dispatch({
            type: CLEAR_NEXT_REQUEST,
            contextId,
            configuration,
            overrides,
        });
    };
}

function resetNextRequest(contextId) {
    return (dispatch, getState) => {
        const matchingContext = getMatchingContext(getState(), contextId);
        const configuration = matchingContext.configurationFn(getState());

        dispatch({
            type: RESET_NEXT_REQUEST,
            contextId,
            configuration,
        });
    };
}

function setSelection(contextId, index, selection) {
    return {
        type: SET_SELECTION,
        contextId,
        index,
        selection,
    };
}

function downloadMatchResults(contextId, index) {
    return (dispatch, getState) => {
        const globalState = getState();
        const matchingContext = getMatchingContext(globalState, contextId);
        const indexState = matchingContext.results[index.name];

        return Api.downloadProfileToCandidates({
            candidateIndex: index.name,
            matchRequest: {
                ...matchingContext.lastRequest,
                ...indexState.lastRequest,
            },
            language: globalState.ui.language,
            sortModeGroup: index.sortModeGroup,
            scoreType: index.scoreType,
            expansionType: index.expansionType,
            exportType: index.exportType,
            exportSize: index.exportSize,
        }).then(({response, request}) => {
            const details = ContentDisposition.parse(
                request.getResponseHeader("content-disposition")
            );
            const contentType = request.getResponseHeader("Content-Type");
            const blob = new Blob([response], {type: contentType});
            blob.name = details.parameters.filename;
            const reader = new FileReader();
            reader.onload = e => {
                const anchor = document.createElement("a");
                anchor.style.display = "none";
                anchor.href = e.target.result;
                anchor.download = blob.name;
                anchor.click();
            };
            reader.readAsDataURL(blob);
        });
    };
}

export function setIndexNextRequest(contextId, index, nextRequest) {
    return {
        type: SET_INDEX_NEXT_REQUEST,
        contextId,
        index,
        nextRequest,
    };
}

function setSortMode(contextId, index, sortMode) {
    return {
        type: SET_SORT_MODE,
        contextId,
        index,
        sortMode,
    };
}

function setSelectionStatus(contextId, index, selectionStatus) {
    return {
        type: SET_SELECTION_STATUS,
        contextId,
        index,
        selectionStatus,
    };
}

function updateUiState(contextId, data) {
    return {
        type: UPDATE_UI_STATE,
        contextId,
        data,
    };
}

function selectAllFromApi(contextId, index) {
    return (dispatch, getState) => {
        const matchingContext = getMatchingContext(getState(), contextId);
        const indexState = matchingContext.results[index.name];

        if (!indexState) {
            dispatch(setSelection(contextId, index, []));
            return;
        }

        return apiCallPromiseForIndex(getState(), contextId, index, {
            from: 0,
            size: index.selectAllLimit,
            _source: false,
        })
            .then(response => {
                const {ids} = convertMatchResponse(response);
                dispatch(setSelection(contextId, index, ids));
            })
            .catch(reason => {
                dispatch(setSelection(contextId, index, []));
                throw reason;
            });
    };
}

function apiCallPromiseForIndex(globalState, contextId, index, overrides) {
    const matchingContext = getMatchingContext(globalState, contextId);
    const indexState = matchingContext.results[index.name];

    return apiCallForResultType(index.resultType)({
        index: index.name,
        matchingStrategy: matchingContext.matchingStrategy,
        matchRequest: {
            ...matchingContext.lastRequest,
            ...indexState.lastRequest,
        },
        from: 0,
        size: 0,
        language: globalState.ui.language,
        sortModeGroup: index.sortModeGroup,
        scoreType: index.scoreType,
        expansionType: index.expansionType,
        conversionPreProcessor: index.apiConversionPreProcessor,
        ...overrides,
    });
}

function apiCallForResultType(resultType) {
    switch (resultType) {
        case ProfileType.JOB:
            return Api.matchProfileToJobs.bind(Api);

        case ProfileType.CANDIDATE:
            return Api.matchProfileToCandidates.bind(Api);

        default:
            throw new Error(`Unsupported result type: ${resultType}`);
    }
}

function convertMatchResponse({matches, metadata}) {
    const matchesById = {};
    const ids = [];

    for (const match of matches) {
        matchesById[match.id] = match;
        ids.push(match.id);
    }

    return {
        matches: matchesById,
        ids,
        count: metadata.count,
    };
}

function getMatchingContext(state, contextId) {
    const matchingContext = state.matching.contexts[contextId];

    if (matchingContext === undefined) {
        throw new Error(`A matching context with ID ${contextId} doesn't exist.`);
    }

    return matchingContext;
}

function convertOldStyleSelectionData(resultType, data) {
    if (!Array.isArray(data)) {
        return data;
    }

    if (resultType === ProfileType.CANDIDATE) {
        return {candidatesForJob: data};
    } else {
        return {jobsForCandidate: data};
    }
}

function getExcludedIds(backendSelection, resultType) {
    if (resultType === ProfileType.CANDIDATE) {
        return backendSelection.candidatesForJob || [];
    } else if (resultType === ProfileType.JOB) {
        return backendSelection.jobsForCandidate || [];
    } else {
        return [];
    }
}
