export function removeUndefinedValues(object) {
    const result = {};

    for (const key in object) {
        if (object.hasOwnProperty(key) && object[key] !== undefined) {
            result[key] = object[key];
        }
    }

    return result;
}

export function removeUndefinedOrNullValues(object) {
    const result = {};

    for (const key in object) {
        if (object.hasOwnProperty(key) && object[key] !== undefined && object[key] !== null) {
            result[key] = object[key];
        }
    }

    return result;
}

export function removeDuplicateValues(array) {
    return [...new Set(array)];
}

export function isEmptyObject(obj) {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
}

export function hasEnabledFilters(filters) {
    for (const name in filters) {
        if (filters[name].enabled) {
            return true;
        }
    }

    return false;
}

export function percentageString(value) {
    return Math.round(value * 100) + "\xa0%";
}

export function convertArrayToSelection(input) {
    if (input === undefined) return undefined;
    const selection = {};
    for (const id of input) selection[id] = true;
    return selection;
}

export function addToExistingSelection(existingSelection, selection) {
    if (existingSelection === undefined || selection === undefined) {
        return;
    }

    if (Array.isArray(selection)) {
        for (const id of selection) {
            if (!existingSelection.includes(id)) {
                existingSelection.push(id);
            }
        }
    } else {
        for (const id in selection) {
            if (selection[id] === true) {
                if (!existingSelection.includes(id)) {
                    existingSelection.push(id);
                }
            }
        }
    }
}

/**
 * @param {any} v The object to inspect.
 * @returns {boolean} True if the argument appears to be a plain object.
 */
export function isPlainObject(v) {
    if (typeof v !== "object" || v === null) return false;

    const prototype = Object.getPrototypeOf(v);
    if (prototype === null) return true;

    let basePrototype = prototype;
    while (Object.getPrototypeOf(basePrototype) !== null) {
        basePrototype = Object.getPrototypeOf(basePrototype);
    }

    return prototype === basePrototype;
}

export function isString(v) {
    return typeof v === "string" || v instanceof String;
}

export function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";
}
