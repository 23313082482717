import * as immutable from "object-path-immutable";
import config from "~/config";
import {
    BACKEND_SELECTION_RECEIVED,
    BACKEND_SELECTION_REQUEST_ERROR,
    BACKEND_SELECTION_REQUESTED,
    CREATE_CONTEXT,
    CLEAR_NEXT_REQUEST,
    SET_MATCHING_STRATEGY,
    SET_NEXT_REQUEST,
    SET_QUERY,
    SET_SELECTION,
    SET_SORT_MODE,
    NEW_MATCH_STARTED,
    PAGE_RECEIVED,
    PAGE_REQUEST_ERROR,
    PAGE_REQUESTED,
    SET_DISPLAYED_PAGE,
    SET_INDEX_LAST_REQUEST,
    SET_MATCH_SOURCE_ENTITY,
    SET_SELECTION_STATUS,
    SET_INDEX_NEXT_REQUEST,
    SET_LAST_REQUEST,
    UPDATE_UI_STATE,
    REPLACE_MATCH,
    MATCH_SOURCE_ENTITY_LOADED,
    RESET_NEXT_REQUEST,
} from "~/actions/matching";
import {
    EMPTY_PREDICATE_WITH_KEYS,
    generateKeysForPredicate,
} from "~/components/PredicateEditor/util";
import {importProfile} from "~/util/match-profile";
import emptyMatchProfile from "~/data/empty-match-profile.json";
import FetchState from "~/enums/FetchState";
import {Sections} from "~/components/MatchingPage/Layout";
import BackendSelection from "~/util/BackendSelection";

let EMPTY_MATCH_PROFILE = importProfile(emptyMatchProfile);

config.changeCallback(() => {
    // TODO: Better solution...
    Object.assign(EMPTY_MATCH_PROFILE, importProfile(EMPTY_MATCH_PROFILE));
});

const EMPTY_RESULT_PAGE = {
    ids: [],
    promise: undefined,
    state: FetchState.NOT_FETCHED,
};

const EMPTY_GLOBAL_REQUEST = {
    matchProfile: EMPTY_MATCH_PROFILE,
};

const EMPTY_PER_INDEX_REQUEST = {
    sortMode: undefined,
    filters: {},
    customFiltersPredicate: EMPTY_PREDICATE_WITH_KEYS,
    excludedIds: [],
};

const EMPTY_RESULTS = {
    nextRequest: EMPTY_PER_INDEX_REQUEST,
    lastRequest: EMPTY_PER_INDEX_REQUEST,
    matches: {},
    pages: {},
    count: undefined, // undefined indicates never searched
    displayedPage: 0,
    selection: [],
    selectionStatus: null, // External status ID to use when making the selection on the backend
    backendSelection: {
        data: BackendSelection.empty(),
        state: FetchState.NOT_FETCHED,
        promise: undefined,
        lastRequestAt: undefined,
        isMakingSelection: false,
    },
};

const EMPTY_MATCH_CONTEXT = {
    configurationFn: undefined,
    query: "",
    matchingStrategy: null,
    nextRequest: EMPTY_GLOBAL_REQUEST,
    lastRequest: EMPTY_GLOBAL_REQUEST,
    matchSourceEntity: undefined,
    results: {}, // by index name // TODO: Rename to "indices"
    defaultFilters: {},
    defaultCustomFiltersPredicate: EMPTY_PREDICATE_WITH_KEYS,
    ui: {
        sections: {
            [Sections.MATCH_PROFILE]: true,
            [Sections.RESULTS]: false,
        },
    },
};

const INITIAL_STATE = {
    contexts: {},
};

export default function(state = INITIAL_STATE, action) {
    if (action.contextId !== undefined && state.contexts[action.contextId] === undefined) {
        console.error(`A matching context with ID ${action.contextId} doesn't exist.`);
    }

    switch (action.type) {
        case CREATE_CONTEXT:
            return createContext(state, action.id, action.configurationFn, action.configuration);

        case NEW_MATCH_STARTED:
            return newMatchStarted(state, action.contextId, action.singleIndex);

        case SET_LAST_REQUEST:
            return setLastRequest(state, action.contextId, action.lastRequest);

        case SET_INDEX_LAST_REQUEST:
            return setIndexLastRequest(state, action.contextId, action.index, action.lastRequest);

        case PAGE_REQUESTED:
            return pageRequested(
                state,
                action.contextId,
                action.index,
                action.pageNumber,
                action.promiseId,
                action.promise
            );

        case PAGE_RECEIVED:
            return pageReceived(
                state,
                action.contextId,
                action.index,
                action.pageNumber,
                action.promiseId,
                action.matches,
                action.ids,
                action.count
            );

        case PAGE_REQUEST_ERROR:
            return pageRequestError(
                state,
                action.contextId,
                action.index,
                action.pageNumber,
                action.promiseId
            );

        case BACKEND_SELECTION_REQUESTED:
            return backendSelectionRequested(
                state,
                action.contextId,
                action.index,
                action.promiseId,
                action.promise,
                action.isMakingSelection
            );

        case BACKEND_SELECTION_RECEIVED:
            return backendSelectionReceived(state, action.contextId, action.index, action.promiseId, action.data);

        case BACKEND_SELECTION_REQUEST_ERROR:
            return backendSelectionRequestError(state, action.contextId, action.index, action.promiseId);

        case SET_DISPLAYED_PAGE:
            return setDisplayedPage(state, action.contextId, action.index, action.pageNumber);

        case SET_MATCH_SOURCE_ENTITY:
            return setMatchSourceEntity(
                state,
                action.contextId,
                action.configuration,
                action.matchSourceEntity
            );

        case MATCH_SOURCE_ENTITY_LOADED:
            return matchSourceEntityLoaded(
                state,
                action.contextId,
                action.configuration,
                action.matchSourceEntity
            );

        case SET_QUERY:
            return setQuery(state, action.contextId, action.query);

        case SET_MATCHING_STRATEGY:
            return setMatchingStrategy(state, action.contextId, action.matchingStrategy);

        case SET_NEXT_REQUEST:
            return setNextRequest(state, action.contextId, action.nextRequest);

        case CLEAR_NEXT_REQUEST:
            return clearNextRequest(
                state,
                action.contextId,
                action.configuration,
                action.overrides
            );

        case RESET_NEXT_REQUEST:
            return resetNextRequest(state, action.contextId, action.configuration);

        case SET_SELECTION:
            return setSelection(state, action.contextId, action.index, action.selection);

        case SET_SORT_MODE:
            return setSortMode(state, action.contextId, action.index, action.sortMode);

        case SET_INDEX_NEXT_REQUEST:
            return setIndexNextRequest(state, action.contextId, action.index, action.nextRequest);

        case SET_SELECTION_STATUS:
            return setSelectionStatus(
                state,
                action.contextId,
                action.index,
                action.selectionStatus
            );

        case UPDATE_UI_STATE:
            return updateUiState(state, action.contextId, action.data);

        case REPLACE_MATCH:
            return replaceMatch(
                state,
                action.contextId,
                action.indexName,
                action.matchId,
                action.data
            );

        default:
            return state;
    }
}

function createContext(state, id, configurationFn, configuration) {
    if (state.contexts[id] !== undefined) {
        console.error(`A matching context with ID ${id} already exists!`);
    }

    const matchContext = {
        ...EMPTY_MATCH_CONTEXT,
        configurationFn,
        defaultFilters: configuration.defaultFilters,
        defaultCustomFiltersPredicate: generateKeysForPredicate(
            configuration.defaultCustomFiltersPredicate
        ),
        results: configuration.indices.reduce((results, index) => {
            results[index.name] = {
                ...EMPTY_RESULTS,
                selectionStatus: index.selectionStatusDefault,
            };

            results[index.name] = {
                ...results[index.name],
                nextRequest: {
                    ...results[index.name].nextRequest,
                    ...getDefaultFilters(index),
                },
            };

            return results;
        }, {}),
    };

    return immutable.set(state, ["contexts", id], matchContext);
}

function newMatchStarted(state, contextId, singleIndex) {
    return immutable.update(state, ["contexts", contextId, "results"], results => {
        const nextResults = {...results};

        for (const indexName in nextResults) {
            if (!results.hasOwnProperty(indexName)) continue;
            if (singleIndex !== undefined && indexName !== singleIndex.name) continue;

            for (const page of Object.values(nextResults[indexName].pages)) {
                if (page.state === FetchState.FETCHING && page.promise) {
                    page.promise.abort();
                }
            }

            nextResults[indexName] = {
                ...nextResults[indexName],
                matches: {},
                pages: {},
                count: undefined, // undefined indicates never searched
                displayedPage: 0,
                selection: [],
            };

            // TODO: Put these things deeper, so the reset can be cleaner?
        }

        return nextResults;
    });
}

function setLastRequest(state, contextId, lastRequest) {
    return immutable.set(state, ["contexts", contextId, "lastRequest"], lastRequest);
}

function setIndexLastRequest(state, contextId, index, lastRequest) {
    return immutable.set(
        state,
        ["contexts", contextId, "results", index.name, "lastRequest"],
        lastRequest
    );
}

function pageRequested(state, contextId, index, pageNumber, promiseId, promise) {
    return updatePageState(state, contextId, index, pageNumber, {
        promiseId,
        promise,
        state: FetchState.FETCHING,
    });
}

function pageReceived(state, contextId, index, pageNumber, promiseId, matches, ids, count) {
    if (!isCorrectPagePromise(state, contextId, index, pageNumber, promiseId)) {
        return state;
    }

    let nextState = updatePageState(state, contextId, index, pageNumber, {
        ids,
        promise: undefined,
        state: FetchState.FETCHED,
    });

    nextState = immutable.merge(
        nextState,
        ["contexts", contextId, "results", index.name, "matches"],
        matches
    );

    nextState = immutable.set(
        nextState,
        ["contexts", contextId, "results", index.name, "count"],
        count
    );

    nextState = immutable.set(
        nextState,
        ["contexts", contextId, "results", index.name, "displayedPage"],
        pageNumber
    );

    return nextState;
}

function pageRequestError(state, contextId, index, pageNumber, promiseId) {
    if (!isCorrectPagePromise(state, contextId, index, pageNumber, promiseId)) {
        return state;
    }

    return updatePageState(state, contextId, index, pageNumber, {
        promise: undefined,
        state: FetchState.NOT_FETCHED,
    });
}

function backendSelectionRequested(state, contextId, index, promiseId, promise, isMakingSelection) {
    return immutable.assign(
        state,
        ["contexts", contextId, "results", index.name, "backendSelection"],
        {
            state: FetchState.FETCHING,
            lastRequestAt: new Date(),
            promiseId,
            promise,
            isMakingSelection,
        }
    );
}

function backendSelectionReceived(state, contextId, index, promiseId, data) {
    if (!isCorrectPagePromise(state, contextId, index, promiseId)) {
        return state;
    }

    return immutable.assign(
        state,
        ["contexts", contextId, "results", index.name, "backendSelection"],
        {
            state: FetchState.FETCHED,
            data: BackendSelection.fromApi(data),
            isMakingSelection: false,
        }
    );
}

function backendSelectionRequestError(state, contextId, index, promiseId) {
    if (!isCorrectPagePromise(state, contextId, index, promiseId)) {
        return state;
    }

    return immutable.assign(
        state,
        ["contexts", contextId, "results", index.name, "backendSelection"],
        {
            data: {},
            state: FetchState.NOT_FETCHED,
            isMakingSelection: false,
            lastRequestAt: undefined,
            promise: undefined,
        }
    );
}

function setDisplayedPage(state, contextId, index, pageNumber) {
    return immutable.set(
        state,
        ["contexts", contextId, "results", index.name, "displayedPage"],
        pageNumber
    );
}

function setMatchSourceEntity(state, contextId, configuration, matchSourceEntity) {
    const nextState = immutable.set(
        state,
        ["contexts", contextId, "matchSourceEntity"],
        matchSourceEntity
    );

    return clearNextRequest(nextState, contextId, configuration);
}

function matchSourceEntityLoaded(state, contextId, configuration, matchSourceEntity) {
    if (matchSourceEntity !== immutable.get(state, ["contexts", contextId, "matchSourceEntity"])) {
        return state;
    }

    let nextState = clearNextRequest(state, contextId, configuration, {});
    nextState = setNextRequest(nextState, contextId, {
        ...EMPTY_GLOBAL_REQUEST,
        matchProfile: matchSourceEntity.document.matchProfile,
    });

    return nextState;
}

function updatePageState(state, contextId, index, pageNumber, data) {
    return immutable.set(
        state,
        ["contexts", contextId, "results", index.name, "pages", pageNumber],
        {
            ...EMPTY_RESULT_PAGE,
            ...state.contexts[contextId].results[index.name].pages[pageNumber],
            ...data,
        }
    );
}

function setQuery(state, contextId, query) {
    return immutable.set(state, ["contexts", contextId, "query"], query);
}

function setMatchingStrategy(state, contextId, matchingStrategy) {
    return immutable.set(state, ["contexts", contextId, "matchingStrategy"], matchingStrategy);
}

function setNextRequest(state, contextId, nextRequest) {
    return immutable.set(state, ["contexts", contextId, "nextRequest"], nextRequest);
}

function clearNextRequest(state, contextId, configuration, overrides) {
    let nextState = immutable.set(state, ["contexts", contextId, "nextRequest"], {
        ...EMPTY_GLOBAL_REQUEST,
        ...overrides,
    });

    return immutable.update(nextState, ["contexts", contextId, "results"], results => {
        const nextResults = {...results};

        for (const indexName in nextResults) {
            if (!results.hasOwnProperty(indexName)) continue;

            nextResults[indexName] = {
                ...nextResults[indexName],
                nextRequest: {
                    ...EMPTY_PER_INDEX_REQUEST,
                    ...getDefaultFilters(configuration.indices.find(x => x.name === indexName)),
                },
            };
        }

        return nextResults;
    });
}

function resetNextRequest(state, contextId, configuration) {
    let nextState = clearNextRequest(state, contextId, configuration);
    const matchSourceEntity = immutable.get(state, ["contexts", contextId, "matchSourceEntity"]);

    if (matchSourceEntity && matchSourceEntity.document) {
        nextState = setNextRequest(state, contextId, {
            ...immutable.get(state, ["contexts", contextId, "nextRequest"]),
            matchProfile: matchSourceEntity.document.matchProfile,
        });
    }

    return nextState;
}

function setSelection(state, contextId, index, selection) {
    return immutable.set(
        state,
        ["contexts", contextId, "results", index.name, "selection"],
        selection
    );
}

function setSortMode(state, contextId, index, sortMode) {
    return immutable.set(
        state,
        ["contexts", contextId, "results", index.name, "nextRequest", "sortMode"],
        sortMode
    );
}

function setIndexNextRequest(state, contextId, index, nextRequest) {
    return immutable.set(
        state,
        ["contexts", contextId, "results", index.name, "nextRequest"],
        nextRequest
    );
}

function setSelectionStatus(state, contextId, index, selectionStatus) {
    return immutable.set(
        state,
        ["contexts", contextId, "results", index.name, "selectionStatus"],
        selectionStatus
    );
}

function updateUiState(state, contextId, data) {
    return immutable.update(state, ["contexts", contextId, "ui"], prevData => ({
        ...prevData,
        ...data,
    }));
}

function replaceMatch(state, contextId, indexName, matchId, data) {
    return immutable.set(
        state,
        ["contexts", contextId, "results", indexName, "matches", matchId],
        data
    );
}

function getDefaultFilters(index) {
    return {
        filters: index.defaultFilters || {},
        customFiltersPredicate: index.defaultCustomFiltersPredicate
            ? generateKeysForPredicate(index.defaultCustomFiltersPredicate)
            : EMPTY_PREDICATE_WITH_KEYS,
    };
}

function isCorrectPagePromise(state, contextId, index, pageNumber, promiseId) {
    return (
        immutable.get(
            state,
            ["contexts", contextId, "results", index.name, "pages", pageNumber, "promiseId"],
            undefined
        ) === promiseId
    );
}

function isCorrectBackendSelectionPromise(state, contextId, index, promiseId) {
    return (
        immutable.get(
            state,
            ["contexts", contextId, "results", index.name, "backendSelection", "promiseId"],
            undefined
        ) === promiseId
    );
}