import urlJoin from "url-join";
import buildQuery from "~/util/build-query";
import {sharedAuth0Client} from "~/util/auth0-provider";
import config from "~/config";

/**
 * Returns an abortable API call, which is a Promise with an extra .abort() function.
 *
 * @param method
 * @param endpoint
 * @param queryParameters
 * @param body
 * @param headers
 * @param dontDecode
 * @param responseType
 * @returns {Promise}
 */
export default function call(
    method,
    endpoint,
    queryParameters = {},
    body = null,
    headers = {},
    dontDecode = false,
    responseType = null
) {
    const request = new XMLHttpRequest();

    // Create a Promise to handle the result
    const promise = new Promise(async (resolve, reject) => {
        // Create request URL
        const queryString = buildQuery(queryParameters, true);
        const url = urlJoin(config("api.url"), endpoint, queryString);
        request.open(method, url);

        // Set headers
        for (const name in headers) {
            request.setRequestHeader(name, headers[name]);
        }

        if (config("api.additionalHeaders", undefined) !== undefined) {
            const additionalHeaders = config("api.additionalHeaders");

            for (const name in additionalHeaders) {
                request.setRequestHeader(name, additionalHeaders[name]);
            }
        }

        // Set response type if defined
        if (responseType) {
            request.responseType = responseType;
        }

        if (internalToken !== null) {
            request.setRequestHeader("Authorization", `Bearer ${internalToken}`);
        } else if (config("features.auth0.enabled", false) && sharedAuth0Client) {
            const token = await sharedAuth0Client.getTokenSilently();

            if (token) {
                request.setRequestHeader("Authorization", `Bearer ${token}`);
            }
        }

        // Register event handlers
        request.onabort = () => {
            reject({
                request,
                status: 499,
                statusText: "Client Closed Request",
                responseText: "",
            });
        };

        request.onerror = () => {
            reject({
                request,
                status: request.status,
                statusText: request.statusText,
                responseText: request.responseText,
            });
        };

        request.onload = () => {
            if (request.status >= 200 && request.status < 300) {
                extractInternalToken(request);

                if (
                    !dontDecode &&
                    (request.responseType === "json" ||
                        request.getResponseHeader("Content-Type") === "application/json")
                ) {
                    resolve({request, response: JSON.parse(request.response)});
                } else {
                    resolve({request, response: request.response});
                }
            } else {
                reject({
                    request,
                    status: request.status,
                    statusText: request.statusText,
                    responseText: request.responseText,
                });
            }
        };

        // Execute request
        if (body !== null) {
            if (headers["Content-Type"] === undefined) {
                request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                request.send(JSON.stringify(body));
            } else {
                request.send(body);
            }
        } else {
            request.send();
        }
    });

    promise.abort = function() {
        request.abort();
    };

    return preserveAbort(promise);
}

let internalToken = null;

export function setInternalToken(token) {
    internalToken = token;
}

function extractInternalToken(request) {
    const headerToken = request.getResponseHeader("X-TMB-Token");

    if (headerToken) {
        setInternalToken(headerToken);
    }
}

function preserveAbort(promise) {
    const originalThen = promise.then.bind(promise);
    const originalCatch = promise.catch.bind(promise);
    const originalFinally = promise.finally.bind(promise);

    promise.then = function(onFulfilled, onRejected) {
        const resultingPromise = originalThen(onFulfilled, onRejected);
        resultingPromise.abort = this.abort;
        return preserveAbort(resultingPromise);
    };

    promise.catch = function(onRejected) {
        const resultingPromise = originalCatch(onRejected);
        resultingPromise.abort = this.abort;
        return preserveAbort(resultingPromise);
    };

    promise.finally = function(onFinally) {
        const resultingPromise = originalFinally(onFinally);
        resultingPromise.abort = this.abort;
        return preserveAbort(resultingPromise);
    };

    return promise;
}
