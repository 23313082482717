import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import Select from "~/components/Select";
import NumberInput from "~/components/NumberInput";
import styles from "./styles.module.scss";

const COMPARISONS = {
    lessThan: {
        label: "<",
        hasInput: true,
        toFilter: (property, input) => ({range: {[property]: {lt: input}}}),
    },
    lessThanOrEqualTo: {
        label: "<=",
        hasInput: true,
        toFilter: (property, input) => ({range: {[property]: {lte: input}}}),
    },
    equalTo: {
        label: "=",
        hasInput: true,
        toFilter: (property, input) => ({term: {[property]: input}}),
    },
    greaterThanOrEqualTo: {
        label: ">=",
        hasInput: true,
        toFilter: (property, input) => ({range: {[property]: {gte: input}}}),
    },
    greaterThan: {
        label: ">",
        hasInput: true,
        toFilter: (property, input) => ({range: {[property]: {gt: input}}}),
    },
    isEmpty: {
        label: {id: "predicateEditor.isEmpty"},
        hasInput: false,
        toFilter: property => ({bool: {must_not: {exists: {field: property}}}}),
    },
    isNotEmpty: {
        label: {id: "predicateEditor.isNotEmpty"},
        hasInput: false,
        toFilter: property => ({bool: {must: {exists: {field: property}}}}),
    },
};

export default class NumberProperty extends React.PureComponent {
    static propTypes = {
        data: PropTypes.shape({
            comparison: PropTypes.oneOf(Object.keys(COMPARISONS)),
            input: PropTypes.number,
        }).isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultValue() {
        return {
            comparison: "equalTo",
            input: null,
        };
    }

    static toFilter(property, data) {
        const comparisonInfo = COMPARISONS[data.comparison];

        if (data.input !== null || !comparisonInfo.hasInput) {
            return comparisonInfo.toFilter(property, data.input);
        } else {
            return undefined;
        }
    }

    render() {
        const {data} = this.props;
        const comparisonInfo = COMPARISONS[data.comparison];

        return (
            <React.Fragment>
                <Select
                    className={c(styles.givePriority, styles.comparisonSelect)}
                    selected={data.comparison}
                    onChange={this.handleComparisonChange}
                    options={COMPARISON_OPTIONS}
                    variant="extra-small"
                />
                {comparisonInfo.hasInput && (
                    <NumberInput
                        className={c(
                            styles.givePriority,
                            styles.input,
                            "form-control",
                            "form-control-sm"
                        )}
                        value={data.input}
                        onChange={this.handleInputChange}
                    />
                )}
            </React.Fragment>
        );
    }

    handleComparisonChange = option => {
        const {data, onChange} = this.props;
        const nextData = {...data, comparison: option.value};
        onChange(nextData);
    };

    handleInputChange = input => {
        const {data, onChange} = this.props;
        const nextData = {...data, input};
        onChange(nextData);
    };
}

const COMPARISON_OPTIONS = Object.keys(COMPARISONS).map(key => ({
    label: COMPARISONS[key].label,
    value: key,
}));
