import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import {FormattedMessage} from "react-intl";
import Table from "react-bootstrap/Table";
import Match from "./Match";
import styles from "./match-list.module.scss";
import TranslatableLabelPropType from "~/prop-types/translatable-label";
import TranslatableLabel from "~/components/TranslatableLabel";
import BackendSelection from "~/util/BackendSelection";
import Checkbox from "~/components/Checkbox";
import SelectAllState from "~/enums/SelectAllState";

export default class MatchList extends React.PureComponent {
    static propTypes = {
        matches: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                score: PropTypes.number.isRequired,
                details: PropTypes.object.isRequired,
                explanations: PropTypes.array,
            })
        ).isRequired,
        extraColumns: PropTypes.arrayOf(
            PropTypes.shape({
                label: TranslatableLabelPropType.isRequired,
                property: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
            })
        ),
        matchInformationGetters: PropTypes.objectOf(PropTypes.func).isRequired,
        allowSelection: PropTypes.bool.isRequired,
        selection: PropTypes.object.isRequired,
        selectAllState: SelectAllState.propType,
        existingSelection: PropTypes.instanceOf(BackendSelection),
        noResultsLabel: PropTypes.string.isRequired,
        className: PropTypes.string,
        renderDetailsColumnHeader: PropTypes.func.isRequired,
        renderDetailsColumn: PropTypes.func.isRequired,
        renderMatchActions: PropTypes.func.isRequired,
        extraColumnPropertyRoot: PropTypes.func.isRequired,
        isGreenMatch: PropTypes.func.isRequired,
        openExternalExistingSelection: PropTypes.func,
        onView: PropTypes.func.isRequired,
        onSelectionChange: PropTypes.func,
        onSelectAllChange: PropTypes.func,
    };

    static defaultProps = {
        selection: {},
        allowSelection: false,
        renderMatchActions: () => null,
    };

    constructor(props) {
        super(props);

        this.mainRef = React.createRef();
    }

    scrollToTop() {
        this.mainRef.current.scrollTop = 0;
    }

    render() {
        const {className} = this.props;

        return (
            <div className={c(styles.matchList, className)} ref={this.mainRef}>
                <Table striped className={c(styles.table, styles.stickyHeader)} size="sm">
                    {this.renderHeader()}
                    {this.renderRows()}
                </Table>
            </div>
        );
    }

    renderHeader() {
        const {
            allowSelection,
            existingSelection,
            renderDetailsColumnHeader,
            extraColumns,
            selectAllState,
            onSelectAllChange,
        } = this.props;

        return (
            <thead className={c(styles.header)}>
                <tr>
                    {allowSelection && existingSelection !== undefined && (
                        <th className={styles.existingSelectionColumn}>
                            <div />
                        </th>
                    )}
                    {allowSelection && (
                        <th className={styles.selectionColumn}>
                            <div>
                                {selectAllState && (
                                    <Checkbox
                                        checked={selectAllState === SelectAllState.SELECTED_ALL}
                                        disabled={selectAllState === SelectAllState.SELECTING}
                                        onChange={onSelectAllChange}
                                    />
                                )}
                            </div>
                        </th>
                    )}
                    <th className={c(styles.relevanceColumn, styles.headerCell)}>
                        <div>
                            <FormattedMessage id="column.relevance" />
                        </div>
                    </th>
                    <th className={c(styles.detailsColumn, styles.headerCell)}>
                        <div>{renderDetailsColumnHeader()}</div>
                    </th>
                    {extraColumns &&
                        extraColumns.map(extraColumn => (
                            <th key={extraColumn.property} style={{width: extraColumn.width}}>
                                <div>
                                    <TranslatableLabel value={extraColumn.label} />
                                </div>
                            </th>
                        ))}
                    <th className={c(styles.contextToggleColumn, styles.headerCell)}>
                        <div />
                    </th>
                </tr>
            </thead>
        );
    }

    renderRows() {
        const {
            matches,
            matchInformationGetters,
            allowSelection,
            selection,
            existingSelection,
            noResultsLabel,
            renderDetailsColumn,
            renderMatchActions,
            isGreenMatch,
            extraColumns,
            extraColumnPropertyRoot,
            openExternalExistingSelection,
            onView,
        } = this.props;

        return (
            <tbody>
                {matches.length === 0 && (
                    <tr>
                        <td className="text-center" colSpan={this.columnCount()}>
                            <FormattedMessage id={noResultsLabel} />
                        </td>
                    </tr>
                )}
                {matches.map(match => {
                    return (
                        <Match
                            key={match.id}
                            match={match}
                            matchInformationGetters={matchInformationGetters}
                            existingSelection={existingSelection}
                            openExternalExistingSelection={openExternalExistingSelection}
                            isSelected={selection[match.id] === true}
                            allowSelection={allowSelection}
                            showExistingSelection={existingSelection !== undefined}
                            extraColumns={extraColumns}
                            extraColumnPropertyRoot={extraColumnPropertyRoot}
                            renderDetailsColumn={renderDetailsColumn}
                            renderMatchActions={renderMatchActions}
                            isGreenMatch={isGreenMatch}
                            onView={onView}
                            onSelectedChange={this.handleSelectedChange}
                        />
                    );
                })}
            </tbody>
        );
    }

    columnCount() {
        const {allowSelection, extraColumns, existingSelection} = this.props;

        return (
            3 +
            (extraColumns ? extraColumns.length : 0) +
            (allowSelection ? 1 : 0) +
            (allowSelection && existingSelection !== undefined ? 1 : 0)
        );
    }

    handleSelectedChange = (id, selected) => {
        const {selection, onSelectionChange} = this.props;
        const nextSelection = {...selection};

        if (selected) {
            nextSelection[id] = true;
        } else {
            delete nextSelection[id];
        }

        onSelectionChange(nextSelection);
    };
}
