import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import Tab from "~/components/MatchingPage/ResultsTabBar/Tab";

export default class ResultsTabBar extends React.PureComponent {
    static propTypes = {
        configuration: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        activeIndexName: PropTypes.string.isRequired,
        onActiveIndexNameChange: PropTypes.func.isRequired,
    };

    render() {
        const {configuration, state, activeIndexName} = this.props;

        return (
            <div className={styles.tabBar}>
                {configuration.indices.map((index, key) => {
                    const indexState = state.results[index.name];
                    const isActive = (index.name === activeIndexName);
                    return <Tab key={key} index={index} state={indexState} isActive={isActive} onClick={this.handleTabClick} />;
                })}
            </div>
        );
    }

    handleTabClick = index => {
        this.props.onActiveIndexNameChange(index.name);
    };
}
