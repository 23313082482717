export default class BackendSelection {
    candidatesForJob = {};
    candidatesForCompany = {};
    jobsForCandidate = {};
    companiesForCandidate = {};

    externalSelectionIdByCandidate = {};
    externalSelectionIdByJob = {};
    externalSelectionIdByCompany = {};

    static fromApi(data) {
        const result = new BackendSelection();
        result.candidatesForJob = BackendSelection.convertApiArrayToObject(
            "candidatesForJob",
            data.candidatesForJob
        );
        result.candidatesForCompany = BackendSelection.convertApiArrayToObject(
            "candidatesForCompany",
            data.candidatesForCompany
        );
        result.jobsForCandidate = BackendSelection.convertApiArrayToObject(
            "jobsForCandidate",
            data.jobsForCandidate
        );
        result.companiesForCandidate = BackendSelection.convertApiArrayToObject(
            "companiesForCandidate",
            data.companiesForCandidate
        );
        result.externalSelectionIdByCandidate = data.externalSelectionIdByCandidate || {};
        result.externalSelectionIdByJob = data.externalSelectionIdByJob || {};
        result.externalSelectionIdByCompany = data.externalSelectionIdByCompany || {};
        return result;
    }

    static empty() {
        return new BackendSelection();
    }

    /**
     * Converts an array of IDs received from the API to an object with the IDs as keys and `true` as values. If the
     * received data is undefined, returns undefined.
     *
     * @param name name of the array, used for an error message
     * @param arrayData an array of IDs
     * @returns {object} conversion result
     */
    static convertApiArrayToObject(name, arrayData) {
        if (arrayData === undefined) {
            return {};
        } else if (!Array.isArray(arrayData)) {
            console.error(`Expected backend selection property ${name} to be an array`, arrayData);
            return {};
        }

        return arrayData.reduce((result, id) => {
            result[id] = true;
            return result;
        }, {});
    }

    getSelectionType(entityId, companyId) {
        if (this.candidatesForJob && this.candidatesForJob[entityId] === true) {
            return "candidatesForJob";
        } else if (this.jobsForCandidate && this.jobsForCandidate[entityId] === true) {
            return "jobsForCandidate";
        } else if (this.candidatesForCompany && this.candidatesForCompany[entityId] === true) {
            return "candidatesForCompany";
        } else if (this.companiesForCandidate && this.companiesForCandidate[companyId] === true) {
            return "companiesForCandidate";
        } else {
            return undefined;
        }
    }

    getExternalDetails(entityId, companyId) {
        if (this.candidatesForJob && this.candidatesForJob[entityId] === true) {
            return {externalId: this.externalSelectionIdByCandidate[entityId]};
        } else if (this.jobsForCandidate && this.jobsForCandidate[entityId] === true) {
            return {externalId: this.externalSelectionIdByJob[entityId]};
        } else if (this.candidatesForCompany && this.candidatesForCompany[entityId] === true) {
            return {externalId: this.externalSelectionIdByCandidate[entityId]};
        } else if (this.companiesForCandidate && this.companiesForCandidate[companyId] === true) {
            return {externalId: this.externalSelectionIdByCompany[companyId]};
        } else {
            return undefined;
        }
    }
}
