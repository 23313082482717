import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import config from "~/config";
import MatchProfilePropType from "~/prop-types/match-profile";
import styles from "./styles.module.scss";
import MatchProfileEditor from "~/components/MatchProfileEditor";
import Button from "react-bootstrap/Button";
import {FormattedMessage} from "react-intl";
import FilterEditor from "~/components/FilterEditor";
import MatchBlock from "~/containers/components/MatchBlock";
import PredicateEditor from "~/components/PredicateEditor";
import PredicateEditorPropTypes from "~/components/PredicateEditor/prop-types";

export default class MatchProfile extends React.PureComponent {
    static propTypes = {
        state: PropTypes.object.isRequired,
        matchProfile: MatchProfilePropType.isRequired,
        availableFilters: PropTypes.array.isRequired,
        filters: PropTypes.object.isRequired,
        indexName: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
        matchingStrategy: PropTypes.string,
        matchRequestHasChanged: PropTypes.bool.isRequired,
        expanded: PropTypes.bool.isRequired,
        withMatchBlock: PropTypes.bool.isRequired,
        showResetButton: PropTypes.bool.isRequired,
        showSuggestions: PropTypes.bool.isRequired,
        customFiltersPredicate: PredicateEditorPropTypes.group.isRequired,
        disableCustomFilters: PropTypes.bool.isRequired,
        onToggleExpanded: PropTypes.func.isRequired,
        onMatchProfileChange: PropTypes.func.isRequired,
        onMatch: PropTypes.func.isRequired,
        onClear: PropTypes.func.isRequired,
        onReset: PropTypes.func,
        onFiltersChange: PropTypes.func.isRequired,
        onLanguageChange: PropTypes.func.isRequired,
        onMatchingStrategyChange: PropTypes.func.isRequired,
        onCustomFiltersPredicateChange: PropTypes.func.isRequired,
        matchProfileEditorRef: PropTypes.any,
    };

    render() {
        const {
            state,
            expanded,
            withMatchBlock,
            language,
            matchingStrategy,
            matchRequestHasChanged,
            showResetButton,
            onLanguageChange,
            onMatchingStrategyChange,
            onToggleExpanded,
            onMatch,
        } = this.props;

        return (
            <div className={c(styles.main, {expanded})}>
                <div
                    className={styles.titleBar}
                    style={{cursor: "pointer"}}
                    onClick={onToggleExpanded}
                >
                    <div className={styles.profileLabel}>
                        <i className={c("fas", expanded ? "fa-caret-down" : "fa-caret-right")} />
                        <FormattedMessage id="match.refineProfile" />
                        {state.matchSourceEntity && state.matchSourceEntity.displayName && (
                            <span className="ml-1">({state.matchSourceEntity.displayName})</span>
                        )}
                    </div>
                    <div className="text-nowrap ml-2">
                        <Button size="sm" variant="secondary" onClick={this.handleClear}>
                            <i className="fas fa-ban" />
                        </Button>
                        {showResetButton && (
                            <Button
                                className="ml-2"
                                size="sm"
                                variant="outline-secondary bg-white"
                                onClick={this.handleReset}
                            >
                                <FormattedMessage id="action.reset" />
                            </Button>
                        )}
                    </div>
                    {withMatchBlock && (
                        <MatchBlock
                            className={styles.matchBlock}
                            language={language}
                            matchingStrategy={matchingStrategy}
                            matchRequestHasChanged={matchRequestHasChanged}
                            onLanguageChange={onLanguageChange}
                            onMatchingStrategyChange={onMatchingStrategyChange}
                            onMatch={onMatch}
                        />
                    )}
                </div>
                {this.renderContent()}
            </div>
        );
    }

    renderContent() {
        const {
            expanded,
            indexName,
            availableFilters,
            filters,
            language,
            matchProfile,
            showSuggestions,
            disableCustomFilters,
            onFiltersChange,
            onMatchProfileChange,
            matchProfileEditorRef,
            customFiltersPredicate,
            onCustomFiltersPredicateChange,
        } = this.props;

        const propertyDefinitions = config(`customFilters.${indexName}`, undefined);
        const propertyDefinitionsFiltered = {};

        if (propertyDefinitions) {
            const disabledProperties = config(`ui.disableCustomFilters.${indexName}`, []);

            for (const key in propertyDefinitions) {
                if (!disabledProperties.includes(key)) {
                    propertyDefinitionsFiltered[key] = propertyDefinitions[key];
                }
            }
        }

        return (
            <div className={c(styles.content, {expanded})}>
                <MatchProfileEditor
                    profile={matchProfile}
                    index={indexName}
                    language={language}
                    isEditing={true}
                    isHorizontal={true}
                    showSuggestions={showSuggestions}
                    onChange={onMatchProfileChange}
                    ref={matchProfileEditorRef}
                />
                {!disableCustomFilters && propertyDefinitions && (
                    <React.Fragment>
                        <h5>
                            <FormattedMessage id="match.filters" />
                        </h5>
                        <PredicateEditor
                            value={customFiltersPredicate}
                            maximumDepth={config("ui.customFiltersMaximumDepth")}
                            propertyDefinitions={propertyDefinitionsFiltered}
                            onChange={onCustomFiltersPredicateChange}
                        />
                    </React.Fragment>
                )}
                {availableFilters.length > 0 && (
                    <React.Fragment>
                        <h5>
                            <FormattedMessage id="match.filters" />
                        </h5>
                        <FilterEditor
                            value={filters}
                            availableFilters={availableFilters}
                            onChange={onFiltersChange}
                        />
                    </React.Fragment>
                )}
            </div>
        );
    }

    handleClear = e => {
        e.stopPropagation();
        this.props.onClear();
    };

    handleReset = e => {
        e.stopPropagation();
        this.props.onReset();
    };
}
