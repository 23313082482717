import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import {injectIntl} from "react-intl";
import styles from "./match-list.module.scss";
import BackendSelection from "~/util/BackendSelection";

class ExistingSelectionIndicator extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        entityId: PropTypes.any.isRequired,
        companyId: PropTypes.any,
        existingSelection: PropTypes.instanceOf(BackendSelection),
        openExternalExistingSelection: PropTypes.func,
    };

    render() {
        const {entityId, companyId, existingSelection, openExternalExistingSelection} = this.props;
        const selectionType = existingSelection.getSelectionType(entityId, companyId);

        return (
            <div
                title={this.getTitle(selectionType)}
                className={c({
                    [styles.hidden]: !selectionType,
                    [styles.externalLink]: openExternalExistingSelection !== undefined,
                })}
                onClick={this.handleClick}
            >
                <i className={c("fas", "fa-link", this.getColorClass(selectionType))} />
            </div>
        );
    }

    getColorClass(selectionType) {
        switch (selectionType) {
            case "candidatesForJob":
            case "jobsForCandidate":
                return "text-success";

            default:
                return "text-warning";
        }
    }

    getTitle(selectionType) {
        const {intl} = this.props;
        return selectionType
            ? intl.formatMessage({id: `existingSelection.${selectionType}`})
            : undefined;
    }

    handleClick = e => {
        const {existingSelection, openExternalExistingSelection, entityId, companyId} = this.props;

        if (openExternalExistingSelection === undefined) {
            return;
        }

        e.stopPropagation();

        const externalDetails = existingSelection.getExternalDetails(entityId, companyId);

        if (externalDetails !== undefined) {
            openExternalExistingSelection(externalDetails);
        }
    };
}

export default injectIntl(ExistingSelectionIndicator, {forwardRef: true});
